const Header = () => {

    const handleSideNav = () => {
        document.querySelector(".main-nav").classList.add("slidenav")
    }

    const handleSubMenu = (id) => {
        let target = document.getElementById(id)
        target.classList.toggle('show');
    }

    return (
        <header className="position_top">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-6 col-md-6 col-lg-2">
                        <div className="logo">
                            <a href="/"><img className="header-logo" src="assets/img/logo.png" alt="" /></a>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-10 text-end">
                        <nav className="main-nav">
                            <div className="mobile-menu-logo">
                                <a href="/"><img src="assets/img/logo.png" alt="" /></a>
                            </div>
                            <ul>
                                <li className="has-child active">
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="#">About Us</a>
                                    <i className="bi bi-chevron-down" onClick={() => handleSubMenu("menu-a")}></i>
                                    <ul className="sub-menu" id="menu-a">
                                        <li><a href="/history">Club History</a></li>
                                        <li><a href="https://www.facebook.com/riversidegolfclubmenominee">Club News</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Golf</a>
                                    <i className="bi bi-chevron-down" onClick={() => handleSubMenu("menu-b")}></i>
                                    <ul className="sub-menu" id="menu-b">
                                        <li><a href="https://foreupsoftware.com/index.php/booking/22414#/teetimes">Book Tee Time</a></li>
                                        <li><a href="/membership">Membership</a></li>
                                        <li><a href="/construction">Course Layout</a></li>
                                        <li><a href="/tournaments">Tournaments</a></li>
                                        <li><a href="/lessons">Lessons</a></li>
                                    </ul>
                                </li>
                                <li><a href="/dining">Dining</a></li>
                                <li><a href="/construction">Events</a></li>
                                <li><a href="/contact">Contact</a></li>
                                <li><a href="/calendar">Calendar</a></li>
                            </ul>
                            <div className="hot-line">
                                <div className="icon">
                                    <i><img src="assets/img/icons/phone-white.svg" alt=""/></i>
                                </div>
                                <div className="line-cnt">
                                    
                                    <a href="tel:9068634837">906-863-4837</a>
                                </div>
                            </div>
                            <div className="btn-block">
                                <div className="membership-btn">
                                    <a href="/membership">JOIN<i className="far fa-user"></i></a>
                                </div>
                            </div>
                            <div className="btn-block">
                                <div className="membership-btn">
                                    <a href="https://foreupsoftware.com/index.php/booking/index/22414#/welcome" target="_blank">LOGIN<i className="far fa-user"></i></a>
                                </div>
                            </div>
                        </nav>
                        <div className="mobile-menu" onClick={handleSideNav}>
                            <a href="javascript:void(0)" className="cross-btn">
                                <span className="cross-top"></span>
                                <span className="cross-middle"></span>
                                <span className="cross-bottom"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header