const Calendar = () => {

    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }
    return (

        <div onClick={handleSideNav}>

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Club Calendar</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Club Calendar</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "center", padding: "1em", marginTop: "2em", marginBottom: "2em"}}>
                <iframe title={"Riverside Club Calendar"} src="https://calendar.google.com/calendar/embed?src=330cd0befbb9275c42604a4493160d02feb3ff186a68dd9185cc48426a233af3%40group.calendar.google.com&ctz=America%2FChicago" style={{border: 0, width: "80%", height: 800, frameBorder: 0, scorlling: "no"}}></iframe>
            </div>

        </div>
    )
}

export default Calendar