const Dining = () => {

    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }

    return (
        <div onClick={handleSideNav}>

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Dining</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Dining</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="restaurant-area" style={{marginTop: "10em"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 or2">
                            <div className="restaurant-left">
                                <h2>Restaurant</h2>
                                <p>Indulge in a delightful dining experience at Riverside Country Club with our executive chef putting his personal touch on every dish we serve! Our welcoming clubhouse offers a cozy atmosphere perfect for enjoying delicious meals and refreshing beverages after a round of golf, or all season long. Stunning river views in our Great Hall make for the perfect ambience for that special occasion, or for a more casual night out. Our diverse menu and attentive service ensure a memorable culinary experience! Our Bar Menu is always available, while we expand our offerings to a full Dinner Menu during our evening service.</p>
                                
                                <p>Summer restaurant hours are Wednesday through Saturday from 11AM to 9PM, with Sunday brunch being served weekly from 10AM to 2PM!</p>

                                <p>Winter restaurant hours are Tuesday through Saturday from 5PM to 9PM.</p>

                                <p>Reservations are not currently necessary, but are encouraged! Call today at 906-863-4837 or 906-290-3777 to reserve your table!</p>
                                <ul className="field-points">
                                    <li><i className="bi bi-check2-circle"></i> Indoor Seating</li>
                                    <li><i className="bi bi-check2-circle"></i> Outdoor Seating</li>
                                    <li><i className="bi bi-check2-circle"></i> Dinner Menu</li>
                                    <li><i className="bi bi-check2-circle"></i> Lunch Menu</li>
                                    {/* <li><i className="bi bi-check2-circle"></i> scelerisque sapien massa maxi.</li>
                                    <li><i className="bi bi-check2-circle"></i> varius vehicula libvel archu ru.</li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 or1">
                            <div className="restaurant-images">
                                <div className="img-1">
                                    <img src="assets/img/food-1-resize.jpg" alt="" style={{maxWidth: "371px", maxHeight: "189px"}}/>
                                </div>
                                <div className="img-2">
                                    <img src="assets/img/food-2.jpg" alt="" style={{maxWidth: "325px", maxHeight: "192px"}} />
                                </div>
                                <div className="img-3">
                                    <img src="assets/img/food-3.jpg" alt="" style={{maxWidth: "255px", maxHeight: "177px"}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="serve-area sec-mar-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-6">
                            <div className="serve-images">
                                <div className="img-1">
                                    <img src="assets/img/food-4.jpg" alt="" style={{maxWidth: "371px", maxHeight: "189px"}} />
                                </div>
                                <div className="img-2">
                                    <img src="assets/img/food-5.jpg" alt="" style={{maxWidth: "373px", maxHeight: "194px"}} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6">
                            <div className="serve-content">
                                <h2>What Do We Serve</h2>
                                <p>Check out our full menu below!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                            
            <div className="row" style={{justifyContent: "center", marginBottom: "10em"}}>
                <div className="col-lg-8" style={{textAlign: "center"}}>
                    <img src="assets/img/dinner-menu.jpg" alt="menu" style={{maxWidth: "90%"}}></img>
                </div>
            </div>

            <div className="row" style={{justifyContent: "center", marginBottom: "10em"}}>
                <div className="col-lg-8" style={{textAlign: "center"}}>
                    <img src="assets/img/bar-menu.jpg" alt="menu" style={{maxWidth: "90%"}}></img>
                </div>
            </div>

        </div>
    )
}

export default Dining